import Api from './Api'

export default {
    // getTransactionDateReport(payload) {
    //     return Api().post('api/dashuser/GetTransactionReport/', payload)
    // },

    addPaymentReference(payload) {
        return Api().post('api/dashuser/AddTransferID/', payload)
    },

    getTransactionDateReport(payload) {
        payload.Source="cineco",
        payload.organization_id="3"
        return Api().post('api/dashuser/FinanceTransDate', payload)
    },

    getTransactionDataForCompare(payload) {
        return Api().post('admin/comparetransactions', payload);
    },

    updateDifferenceData(payload) {
        return Api().post('admin/UpdateDifference', payload);
    },

    NonReconciled(payload) {
        return Api().post('admin/NonReconciled', payload);
    },

    ReconciledTransaction(payload) {
        return Api().post('admin/ManualRecociliation', payload);
    },

    RefundTransaction(payload) {
        return Api().post('admin/ProcessRefund', payload);
    },

    getCinemaPaymentList(payload) {
        return Api().post('api/dashuser/CinemaPaymentList', payload)
    },

    getCinemaPaymentListExcel(payload) {
        return Api().post('api/dashuser/CinemaPaymentListExcel', payload)
    },

    getShowDateReport(payload) {
        return Api().post('api/dashuser/GetShowReport/', payload)
    },

    downloadReport(downloadType) {
        return Api().get('api/dashuser/GetReportFile?DownloadType=' + downloadType)
    },
    sendMail(payload) {
        return Api().post('api/dashuser/Resendmail/', payload)
    },
    // getFinanceReport(payload) {
    //     return Api().post('admin/getfinancereports', payload)
    // },
    getFinanceReport(payload) {
        payload.Source="cineco",
        payload.organization_id="3"
        return Api().post('api/dashuser/FinanceShowDate', payload)
    },
    getCompareReport(payload) {
        return Api().post('admin/CompareReport', payload)
    },

}